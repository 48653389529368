<template>
<div class="page">
    <div v-if="!!detail">
        <swiper-common :banners="detail.imageslist" />
        <div class="mediInfo exchange-wrap">
            <div class="price-wrap">
                <div class="price">
                    <span class="now-price"> &yen; {{Stock_SellPrice}}</span> <span class="old-price"> &yen; {{detail.model.Product_OriPrice}}</span>
                </div>
                <div>
                    {{computeStatu(Product_FlashStart,Product_FlashEnd)}}
                </div>
            </div>
            <div class="medTit">
                <span>{{detail.model.Product_Name}}</span>
            </div>
            <div @click="showCart">
                <div class="type-wrp">
                    <span>选择</span>
                    <div class="type-check-wrp" v-if="stockid>0">
                        <span v-if="colorlist.length>=1">{{colorlist[colorIndx]&&colorlist[colorIndx].Stock_Color}}</span>
                        <span v-if="sizelist.length>=1">{{sizelist[sizeIndx]&&sizelist[sizeIndx].Stock_Size}}</span>
                        <span v-if="varietylist.length>=1">{{varietylist[varietyIndx]&&varietylist[varietyIndx].Stock_Variety}}</span>
                    </div>
                    <div v-else class="show-ps">{{"请选取您要的规格" }}</div>
                </div>
            </div>

        </div>

        <div class="states exchange-wrap" style="padding-right:0">
            <div class="tit flxbtw">
                <label> <img src="../assets/local_gray.png" alt=""> 查看地点</label>
                <a class="poi" :href="'https://apis.map.qq.com/uri/v1/marker?marker=coord:'+ Latitude +','+Longitude+';title:'+ address +';&referer=DYKBZ-BT6W3-V5W3X-YVURI-73257-7QBF5'">
                    <div class="addr-wrap"> <span> {{address}}</span></div>
                </a>
            </div>

        </div>
        <div class="exchange-wrap exchange-info-wrap">
            <div class="tit">
                兑换需知
            </div>
            <div class="mediceInfoCont exchange-info" :style="'height:'+exchangeHight">
                <div v-html="detail.model.Product_Brief"></div>
            </div>
            <span class="exchange-info-more" @click="changeHide">显示更多</span>
        </div>

        <div class="mediceInfo">
            <div class="tit" style="padding-left:12px">
                商品信息
            </div>
            <div class="mediceInfoCont">
                <div v-html="detail.model.Product_Detail"></div>
            </div>

        </div>
        <div v-if="cartShow" class="type-inner">
            <div class="close-btn">
                <label class="tit">请选择：</label>
                <img @click="cartShow = false;clickNum = 0;clickCartNum = 0" src="../assets/close.png" alt="" srcset="">
            </div>
            <div class="typelist-wrp">
                <div class="typeslist">
                    <!-- <div class="price">
                        <label class="fs">单价: <span>{{Stock_SellPrice}}</span> 元</label>
                    </div> -->
                    <template v-if="colorlist.length>=2">
                        <div class="typeName">{{detail.model.Product_ColorAlias}}</div>
                        <div class="typeChoose">
                            <div v-for="(item,index) in colorlist" :key="item._rowNumber" :class="{'over':item.Stock_Total==0}">
                                <button :class="index==colorIndx?'on':''" :disabled="item.Stock_Total==0?true:false" @click="actBtn('color',index)">{{item.Stock_Color}}</button>
                            </div>
                        </div>
                    </template>
                    <template v-if="sizelist.length>=2">
                        <div class="typeName">{{detail.model.Product_SizeAlias}}</div>
                        <div class="typeChoose">
                            <div v-for="(item,index) in sizelist" :key="item._rowNumber" :class="{'over':item.Stock_Total==0}">
                                <button :class="index==sizeIndx?'on':''" :disabled="item.Stock_Total==0?true:false" @click="actBtn('size',index)">{{item.Stock_Size}}</button>
                            </div>
                        </div>
                    </template>
                    <template v-if="varietylist.length>=2">
                        <div class="typeName">{{detail.model.Product_VarietyAlias}}</div>
                        <div class="typeChoose">
                            <div v-for="(item,index) in varietylist" :key="item._rowNumber" :class="{'over':item.Stock_Total==0}">
                                <button :class="index==varietyIndx?'on':''" :disabled="item.Stock_Total==0?true:false" @click="actBtn('variety',index)">{{item.Stock_Variety}}</button>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="mediWrap">
                    <div> <label> 购买数量 </label> <span class="stockTal">库存 {{stockTal}} 项</span></div>
                    <div>
                        <div class="cartCount">
                            <span><img src="../assets/minus.png" @click="countMinus" /></span>
                            <span>{{count}}</span>
                            <span><img src="../assets/plus.png" @click="countPlus" /></span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="buyIner">
            <div class="imgShow">
                <a href="javascript:void(0);" @click="toActive" class="act-wrap">
                    <img src="../assets/party.png" alt="">
                    <span>活动</span>
                </a>
                <!-- <a href="javascript:void(0);" @click="toProductCart" class="cart-wrap">
                    <img src="../assets/cart.png" alt="">
                    <span>购物车</span>
                </a> -->
            </div>
            <div class="btm-btn-wrp">
                <!-- <a href="javascript:void(0);" class="cart-wrp" @click="addToCart">加入购物车</a> -->
                <a href="javascript:void(0);" class="price-wrp" @click="justBuy">立即报名</a>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import SwiperCommon from "@/components/SwiperCommon";
export default {
    data() {
        return {
            address: "",
            Latitude: "",
            Longitude: "",
            exchangeHight: '',
            Product_ID: 0,
            pid: 0,
            count: 1,
            fee: 0,
            stockTal: 0,
            activeStatu: "",
            varietyIndx: -1,
            colorIndx: -1,
            sizeIndx: -1,
            colorlist: [],
            sizelist: [],
            varietylist: [],
            domin: this.domin,
            cartShow: false,
            indicatorDots: true,
            autoplay: true,
            heit: "150px",
            interval: 5000,
            duration: 600,
            postData: {
                productid: 0,
                color: "",
                size: "",
                variety: ""
            },
            currentType: "",
            clickNum: 0,
            clickCartNum: 0,
            odetail: null,
            detail: null,
            stockid: 0,
            Stock_Variety: "",
            Stock_Color: "",
            Stock_Size: "",
            Product_FlashStart: "",
            Product_FlashEnd: "",
            Stock_Total: 0,
            Stock_SellPrice: 0,
            buttonGroup: [{
                    span: "加入购物车",
                    backgroundColor: "#ff0000",
                    color: "#fff"
                },
                {
                    span: "立即报名",
                    backgroundColor: "#F97A0A",
                    color: "#fff"
                }
            ]
        };
    },
    components: {
        "swiper-common": SwiperCommon
    },
    mounted() {
        localStorage.setItem("gUrl", this.$route.fullPath||"/");
        console.log("route fullPath",this.$route.fullPath)
        this.pid = this.$route.params.id;
        this.getMediceDetail(this.$route.params.id);
        localStorage.setItem("Product_ID",this.$route.params.id)
        
    },
    methods: {
        changeHide() {
            if (this.exchangeHight == '') {
                this.exchangeHight = 'auto';
            } else {
                this.exchangeHight = '';
            }
        },
        toActive() {
            this.$router.push("/active")
        },
        toProductCart() {
            this.$router.push("/Cart")

        },
        showCart() {
            if (this.colorlist.length == 1 && this.varietylist.length == 1 && this.sizelist.length == 1) {
                return false
            } else {
                this.cartShow = true;
            }
        },
        addToCart() {

            let statuText = this.computeStatu(this.Product_FlashStart, this.Product_FlashEnd)
            if (statuText != "进行中") {
                alert("该活动目前状态为：" + statuText)
                return false
            }

            if (this.clickCartNum == 0) {
                this.clickCartNum += 1

                if (this.sizelist.length == 1 && this.varietylist.length == 1 && this.colorlist.length == 1) {
                    this.cartShow = false
                } else {
                    this.cartShow = true
                    return
                }
            }
            if (this.stockTal == 0) {
                alert("所选产品规格已售罄");
                return false;
            }
            this.wpost({
                url: "/Ajax/WordWelfare/addtocart",
                data: {
                    stockid: this.stockid,
                    count: this.count
                },
                succ: data => {
                    alert("加入购物车成功");
                    this.Stock_Total = data.stock.Stock_Total;
                    this.count = 1;
                }
            });
        },
        countPlus: function () {
            if (this.stockid == 0) {
                alert("请先选择产品对应规格!");
                return false;
            }
            if (this.Stock_Total > this.count) {
                this.count += 1;
            } else {
                alert("产品库存有限!");
                return false;
            }
        },
        countMinus: function () {
            if (this.stockid == 0) {
                alert("请先选择产品对应规格!");
                return false;
            }
            if (this.count > 1) this.count -= 1;
        },
        computeStatu(start, end) {
            let statuText = "";
            let starts = start.replace(/-/g, "/")
            let ends = end.replace(/-/g, "/")
            let stime = new Date(starts).getTime()
            let etime = new Date(ends).getTime()
            let now = Date.now()

            if (now < stime) {
                statuText = "未开始";
            } else if (now > stime && now < etime) {
                statuText = "进行中";

            } else if (now > etime) {
                statuText = "已结束";

            } else {
                statuText = "未知";
            }

            return statuText
        },
        // 立即购买
        justBuy() {
            let statuText = this.computeStatu(this.Product_FlashStart, this.Product_FlashEnd)
            if (statuText != "进行中") {
                alert("该活动目前状态为：" + statuText)
                return false
            }

            if (this.clickNum == 0) {
                this.clickNum += 1
                if (this.sizelist.length == 1 && this.varietylist.length == 1 && this.colorlist.length == 1) {
                    this.cartShow = false
                } else {
                    this.cartShow = true
                    return
                }

            }
            if (this.stockTal == 0) {
                alert("请确认需要购买的规格有足够的库存");
                return false;
            }

            this.$router.push("/productOrderin")
        },
        actBtn(type, index) {

            this.count = 1;

            if (this[type + "Indx"] == index) {
                this[type + "Indx"] = -1;
                this.postData[type] = "";
            } else {
                this[type + "Indx"] = index;
                this.postData[type] = this.detail[type + "list"][index][
                    "Stock_" + type.substring(0, 1).toUpperCase() + type.substring(1)
                ];
            }
            this.postData.productid = this.pid;

            console.log('postData', this.postData)

            let varr = ["color", "size", "variety"];
            varr.map(item => {
                console.log(this.detail[item + "list"]);

                if (this[item + "Indx"] == -1) {
                    this.postData[item] = "";
                }

                if (this.detail[item + "list"].length == 1) {
                    this.postData[item] = this.detail[item + "list"][0][
                        "Stock_" + item.substring(0, 1).toUpperCase() + item.substring(1)
                    ];
                }
            });

            this.getStock(this.postData);

        },
        getStock(postData) {
            // 拉取后端对应标签库存

            this.wpost({
                url: "/Ajax/WordWelfare.ashx?type=getstocklist",
                data: postData,
                succ: data => {
                    console.log("getStock data:", data);
                    this.varietylist = data.varietylist;
                    this.sizelist = data.sizelist;
                    this.colorlist = data.colorlist;
                    if (data.stock.Stock_ID !== 0) {
                        this.stockid = data.stock.Stock_ID;
                        this.stockTal = this.Stock_Total = data.stock.Stock_Total;
                        this.Stock_SellPrice = data.stock.Stock_SellPrice;
                        this.Longitude = data.stock.Stock_Longitude;
                        this.Latitude = data.stock.Stock_Latitude;
                        this.address = data.stock.Stock_ContactAddress

                        // 需要传到购买支付页的内容
                        let item = {
                            count: this.count,
                            stockid: this.stockid,
                            image: this.detail.imageslist[0],
                            sellPrice: data.stock.Stock_SellPrice,
                            name: String(data.model.Product_Name),
                            stock: {
                                Stock_Color: data.stock.Stock_Color,
                                Stock_Variety: data.stock.Stock_Variety,
                                Stock_Size: data.stock.Stock_Size
                            },
                            Freight: data.model.Product_Freight
                        };
                        console.log("get stock then storage item:", item);
                        localStorage.setItem("itemBuy", JSON.stringify(item))

                    } else {
                        this.stockid = 0;
                        this.stockTal = 0;
                        this.Stock_SellPrice = 0;
                        this.Longitude = 0;
                        this.Latitude = 0;
                        this.address = ""

                    }
                }
            });
        },
        // 获取活动产品详情
        getMediceDetail(id) {
            this.wpost({
                url: "/Ajax/WordWelfare/getproductmodel",
                data: {
                    id: id
                },
                succ: data => {
                    console.log("getproductmodel:", data);
                    data.model.Product_Detail = data.model.Product_Detail.replace(
                        /src=\"\/Upload/gi,
                        'src="' + this.domin + "/Upload/"
                    ).replace(/src=\"..\/Upload/gi, 'src="' + this.domin + "/Upload/");
                    this.detail = data;
                    this.Stock_Total = data.stock.Stock_Total;
                    this.Stock_SellPrice = data.stock.Stock_SellPrice;
                    this.stockid = data.stock.Stock_ID;
                    this.Stock_Variety = data.stock.Stock_Variety;
                    this.Stock_Color = data.stock.Stock_Color;
                    this.Stock_Size = data.stock.Stock_Size;
                    this.varietylist = data.varietylist;
                    this.sizelist = data.sizelist;
                    this.colorlist = data.colorlist;
                    this.stockTal = data.stock.Stock_Total;

                    this.Product_FlashStart = data.model.Product_FlashStart
                    this.Product_FlashEnd = data.model.Product_FlashEnd
                    this.Product_Merchant = data.model.Product_Merchant
                    localStorage.setItem("model",JSON.stringify(data.model))
                    localStorage.setItem("Product_Merchant",data.model.Product_Merchant )
                    localStorage.setItem("Product_FlashSale",data.model.Product_FlashSale)
                    localStorage.setItem("Product_HasCertno",data.model.Product_HasCertno)
                    localStorage.setItem("Product_HasSchool",data.model.Product_HasSchool)

                    this.postData.productid = this.pid;
                    this.postData.color = data.stock.Stock_Color;
                    this.postData.size = data.stock.Stock_Size;
                    this.postData.variety = data.stock.Stock_Variety;

                    // 判断是否有规格选中
                    this.sizeIndx = this.sizelist.findIndex(item => {
                        return item.Stock_Size == this.Stock_Size
                    })
                    console.log("this.sizeIndx:", this.sizeIndx);

                    this.varietyIndx = this.varietylist.findIndex(item => {
                        return item.Stock_Variety == this.Stock_Variety
                    })

                    console.log("this.varietyIndx:", this.varietyIndx);

                    this.colorIndx = this.colorlist.findIndex(item => {
                        return item.Stock_Color == this.Stock_Color
                    })

                    console.log("this.colorIndx:", this.colorIndx);

                    // 根据时间，活动状态判断

                    let start = data.model.Product_FlashStart.replace(/-/g, "/");
                    let end = data.model.Product_FlashEnd.replace(/-/g, "/");

                    this.Longitude = this.detail.stock.Stock_Longitude || this.detail.model.Product_Longitude;
                    this.Latitude = this.detail.stock.Stock_Latitude || this.detail.model.Product_Latitude;
                    this.address = this.detail.stock.Stock_ContactAddress || this.detail.model.Product_ContactAddress

                    console.log(this.Latitude, this.Longitude);

                    let nowMiu = Date.now();
                    let startMiu = Date.parse(new Date(start));
                    let endMiu = Date.parse(new Date(end));

                    if (nowMiu >= endMiu) {
                        this.activeStatu = "已结束";
                    } else if (nowMiu < startMiu) {
                        this.activeStatu = "未开始";
                    } else if (nowMiu < endMiu && nowMiu >= startMiu) {
                        this.activeStatu = "进行中";
                    }

                    // 需要传到购买支付页的内容
                    let item = {
                        count: this.count,
                        stockid: this.stockid,
                        image: this.detail.imageslist[0],
                        sellPrice: this.Stock_SellPrice,
                        name: String(this.detail.model.Product_Name),
                        stock: {
                            Stock_Color: this.detail.stock.Stock_Color,
                            Stock_Variety: this.detail.stock.Stock_Variety,
                            Stock_Size: this.detail.stock.Stock_Size
                        },
                        Freight: this.detail.model.Product_Freight
                    };
                    console.log("item:", item);
                    localStorage.setItem("itemBuy", JSON.stringify(item))
                }
            });
        }
    }
};
</script>

<style scoped>
.page {
    background-color: #f5f5f5;
}

.type-inner {
    position: fixed;
    padding-bottom: 12px;
    z-index: 99;
    bottom: 61px;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0 0 5px #ccc;
}

.typelist-wrp {
    padding-top: 30px;
    max-height: 50vh;
    overflow: hidden;
    overflow-y: auto;
}

.type-list-one {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 35px;
}

.price-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.type-list-one span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 43%;
    box-sizing: border-box;
    color: #8c8c8c;
    background-color: #f0f0f0;
    padding: 0 6px;
    height: 30px;
    line-height: 30px;
    border-radius: 6px;
    margin: 3px 5px;
    margin-left: 0;
    text-align: center;
    font-size: 13px;
}

.type-wrp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 0;
}

.type-wrp span {
    color: #999;
    font-size: 13px;
}

.type-check-wrp {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 8px;
    font-size: 14px;
    width: calc(100% - 50px);
}

.show-ps {
    font-size: 12px;
    color: #8c8c8c;
    margin-left: 6px;
}

.type-check-wrp span {
    color: #fff;
    background-color: #f97a0a;
    border: 1px solid #f97a0a;
    padding: 3px 6px;
    border-radius: 2px;
    font-size: 12px;
}

.type-check-wrp::after {
    content: "";
    height: 5px;
    width: 5px;
    position: absolute;
    top: 4px;
    right: 0;
    border: 1px solid transparent;
    border-right-color: #999;
    border-bottom-color: #999;
    transform: rotateZ(-45deg);
}

.mapBg {
    background-color: rgba(0, 0, 0, 0.78);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
}

.mapClsBtn {
    position: absolute;
    bottom: 30vh;
    left: 50%;
    transform: translateX(-50%);
}

.map {
    width: 97%;
    height: 50vh;
    position: absolute;
    top: 7%;
    left: 1.5%;
}

.statuAct {
    color: #f97a0a;
    font-size: 12px;
}

.uni-goods-nav {
    position: fixed;
    z-index: 99;
    left: 0;
    bottom: 10px;
    width: 100%;
}

.fs {
    font-size: 13px;
}

.fs span {
    font-size: 20px;
    font-weight: bold;
    margin: 0 3px;
    color: #f97a0a;
}

.typeChoose {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.typeChoose>div {
    position: relative;
}

.typeChoose>div.over::after {
    content: "售完";
    font-size: 10px;
    background-color: #ccc;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    padding: 0 2px;
    position: absolute;
    top: -7px;
    right: -7px;
}

.poi {
    padding: 12px 0;
    font-weight: normal;
    width: calc(100% - 83px);
    background: url("../assets/map_bg.jpg") no-repeat center center / 100% auto;
}

.addr-wrap {
    position: relative;
    background: #fff;
    padding: 3px 6px;
    border-radius: 16px;
    box-shadow: 0 0 4px #999;
    width: 90%;
    margin: 0 auto;
}

.addr-wrap span {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 0 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    z-index: 6;
    color: #333;
    font-size: 13px;
}

.addr-wrap,
.addr-wrap span {
    height: 27px;
    line-height: 27px;
}

.addr-wrap::after {
    content: "";
    height: 8px;
    width: 8px;
    position: absolute;
    z-index: 5;
    bottom: -9px;
    left: 50%;
    border: 1px solid transparent;
    border-right-color: #aaa;
    border-bottom-color: #aaa;
    transform: rotateZ(45deg) translateX(-50%);
    background: #fff;
}

.typeChoose button {
    margin: 3px;
    color: #383838;
    padding: 3px 5px;
    margin: 6px 0;
    margin-right: 10px;
    font-size: 16px;
    box-sizing: border-box;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
}

.typeChoose button:disabled {
    color: #cbcbcb;
}

.typeChoose button.on {
    color: #fff;
    background-color: #f97a0a;
    border: 1px solid #f97a0a;
}

.typeslist {
    padding: 12px;
    padding-bottom: 56px;
}

.typeName {
    font-weight: bold;
    font-size: 14px;
    line-height: 2;
}

.cartBtn {
    background-color: #f97a0a;
    color: #fff;
    text-align: center;
    font-size: 14px;
    padding: 0 10px;

    line-height: 37px;
    border-radius: 20px;
}

.gouIcon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.priceWrp {
    background-color: #555;
    height: 35px;
    line-height: 35px;
    border-radius: 19px;
    padding: 2px 8px;
    font-size: 13px;
}

.imgShow {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 85px;
    width: 25%;
}

.imgShow a {
    width: 40px;
    box-sizing: border-box;
    padding: 5px 0;
}

.imgShow a,
.imgShow a span {
    display: block;
    text-align: center;
}

.imgShow a img {
    height: 18px;
    padding-bottom: 3px;
}

.imgShow a span {
    font-size: 12px;
}

.btm-btn-wrp {
    width: 69%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
}

.btm-btn-wrp a {
    font-size: 13px;
    display: block;
    width: 97%;
    text-align: center;
    color: #fff;
    padding: 12px 0;
}

.price-wrp {
    background-color: #ff0000;
    border-radius: 26px;
}

.cart-wrp {
    background-color: #ff9100;
    border-radius: 26px;
}

.flxbtw {
    padding: 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flxbtw label {
    color: #999;
    font-weight: normal;
    font-size: 13px;
    display: flex;
    width: 70px;
    justify-content: space-evenly;
    align-items: center;
}

.flxbtw label img {
    width: 11px;
    vertical-align: middle;
}

.buyIner {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    height: 60px;
    background-color: #fff;
    position: fixed;
    z-index: 77;
    bottom: 0;
}

.checkIntro {
    margin: 10px 0;
    border: 1px solid #ccc;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 17px;
    border-radius: 20px;
}

.moreInfo {
    padding: 12px;
    color: #999;
    font-size: 11px;
}

.ritarr {
    float: right;
}

.states {
    background-color: #fff;
    padding: 2px 12px;
    margin: 12px 0;
}

.exchange-info {
    padding-top: 12px;
    font-size: 13px;
    line-height: 1.5;
    height: 120px;
    overflow: hidden;
}

.exchange-info-wrap {
    padding: 8px 12px;
    background-color: #fff;
    padding-bottom: 30px;
    position: relative;
}

.exchange-info-more {
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px;
    color: #999;
    bottom: 5px;
    text-align: center;
    position: absolute;

}

.exchange-info-wrap .tit {
    font-weight: bold;
    font-size: 15px;
}

.exchange-wrap {
    border-radius: 8px;
    margin: 12px 8px;
    overflow: hidden;
}

.intrDcWrp {
    background-color: #fff;
    margin: 12px 0;
    padding: 2px 12px;
}

.introCont {
    font-size: 12px;
}

.introCont span {
    color: #666;
}

.comments {
    padding: 12px;
    font-size: 15px;
    font-weight: bold;
    background-color: #fff;
}

.comments span {
    font-size: 12px;
    color: #999;
}

.mediceInfo .moreCheck {
    font-size: 15px;
    text-align: center;
    padding: 12px;
}

.mediceInfo {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
}
.mediceInfoCont{
    width: 100%;
    overflow: hidden;
}

.mediceInfo .tit {
    border-bottom: 1px solid #ccc;
}

.states .tit,
.intrDcWrp .tit,
.mediceInfo .tit {
    font-size: 15px;
    font-weight: bold;
    padding: 10px 0;
    box-sizing: border-box;
}

.statesWrp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 10px;
}

.stateOne {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 33.333%;
    margin: 3px 0;
    color: #666;
    font-size: 12px;
}

.swiper,
.swiper image {
    width: 100%;
}

.medTit {
    font-size: 17px;
    font-weight: bold;
}

.close-btn {
    position: absolute;
    top: 0;
    z-index: 9;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0 0 2px #ccc
}

.close-btn label {
    font-size: 15px;
    color: #333;
    font-weight: bold;
    line-height: 35px;
    padding-left: 10px;
}

.close-btn img {
    margin: 6px;
    width: 22px;
}

.mediWrap {
    padding: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 61px;
    box-sizing: border-box;
    width: 100%;
    z-index: 10;
    background-color: #fff;
    box-shadow: 0 0 1px #ccc;
}

.mediWrap label {
    font-size: 14px;
    font-weight: bold;
}

.mediInfo {
    padding: 12px 6px;
}

.mediInfo,
.swiper {
    background-color: #fff;
}

.price span {
    display: inline-block;
    font-size: 21px;
    color: #e62d56;
    padding-bottom: 6px;
    padding-left: 8px;
}

.price span.old-price {
    color: #999;
    text-decoration: line-through;
    font-size: 12px;
}

.stockTal {
    font-size: 12px;
    text-align: center;
    color: #999;
}

.price span.fs {
    font-size: 12px;
}

.cartCount {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 17px;
    color: #333;
    background-color: #fff;
}

.cartCount span {
    background-color: #f1f1f1;
    margin: 0 0.5px;
    display: inline-block;
    width: 27px;
    line-height: 27px;
    height: 27px;
    text-align: center;
    vertical-align: middle;
}

.cartCount img {
    width: 16px;
    padding: 4px;
}
</style>
